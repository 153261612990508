import React, { useEffect } from "react";
import "./PrivacyPolicy.css";

const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="privacy-container">
      <div className="privacy-content">
        <h1>Privacy Policy for Easefarming Partner App</h1>
        <p className="last-updated">Last Updated: <strong>25 Feb 2025</strong></p>

        <section>
          <h2>1. Introduction</h2>
          <p>
            Welcome to the Easefarming Partner App (“App”), a service designed to
            empower retailers to sell agricultural inputs (pesticides,
            fertilizers, tools) directly to farmers via the Easefarming platform.
            We are committed to protecting your privacy and ensuring transparency
            about how we collect, use, and safeguard your data. By using this
            App, you agree to the terms outlined in this Privacy Policy.
          </p>
        </section>

        <section>
          <h2>2. Information We Collect</h2>
          <h3>a. Personal Information</h3>
          <ul>
            <li>Account Details: Name, email, phone number, business address, and payment information.</li>
            <li>
              Location Data: The App collects your device’s precise location to notify you in real-time when farmers search for specific products.
            </li>
          </ul>
          <h3>b. Usage Data</h3>
          <p>App interactions, search queries, transaction history, and preferences.</p>
          <h3>c. Farmer Interaction Data</h3>
          <p>Product requests, purchase history, and anonymized farmer location data.</p>
        </section>

        <section>
          <h2>3. How We Use Your Information</h2>
          <ul>
            <li>Match retailers with farmers based on real-time location.</li>
            <li>Send alerts about nearby farmer product searches.</li>
            <li>Facilitate transactions and inventory management.</li>
            <li>Improve app functionality and personalize recommendations.</li>
            <li>Send service updates, security alerts, and promotional offers.</li>
          </ul>
        </section>

        <section>
          <h2>4. Location Data Usage</h2>
          <p>You can disable background location permissions via device settings, but this may limit the app’s functionality.</p>
        </section>

        <section>
          <h2>5. Data Sharing</h2>
          <ul>
            <li>We do not sell your personal information.</li>
            <li>Your business location and product listings are visible to farmers.</li>
            <li>Data may be shared with service providers (e.g., payment processors) and legal authorities when required.</li>
          </ul>
        </section>

        <section>
          <h2>6. Data Security</h2>
          <p>We use encryption, secure servers, and access controls to protect your data.</p>
        </section>

        <section>
          <h2>7. Your Choices & Rights</h2>
          <p>You can adjust location permissions or request account deletion by contacting us at <a href="mailto:support@easefarming.com">support@easefarming.com</a>.</p>
        </section>

        <section>
          <h2>8. Data Retention</h2>
          <p>We retain data only as necessary. Location data is deleted or anonymized after 90 days unless needed for fraud prevention.</p>
        </section>

        <section>
          <h2>9. Policy Updates</h2>
          <p>We may update this policy periodically. Continued use of the App constitutes acceptance.</p>
        </section>

        <section>
          <h2>10. Contact Us</h2>
          <p>Email: <a href="mailto:support@easefarming.com">support@easefarming.com</a></p>
          <p>Address: T-Hub, Plot No 1/C, Sy No 83/1, Raidurgam, Knowledge City Rd, Panmaktha, Hyderabad, Telangana 500081</p>
        </section>
      </div>
    </div>
  );
};

export default PrivacyPolicy;