import LandingPage from "../LandingPage";
import Component2 from "../component2";
import Component3 from "../component3";
import Component4 from "../component4";


const Home = () => {

    return (
        <>
            <LandingPage />
            <Component2 />
            <Component3 />
            <Component4 />
        </>
    )
}

export default Home;