import "./App.scss";
// importing aos
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Layout from "./components/Layout/Layout"
import Home from "./components/Home/Home"
import PrivacyPolicy from "./components/contactUs/PrivacyPolicy/PrivacyPolicy.jsx";
import {PRIVACY_POLICY_PAGE_URL} from "./components/constants.js"

function App() {
  useEffect(() => {
    AOS.init();
  }, [])

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path={PRIVACY_POLICY_PAGE_URL} element={<PrivacyPolicy />} />
        </Route>
      </Routes>
    </Router>
  );

}

export default App;
